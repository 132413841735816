import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import LayoutStyles from "../components/signup/layout.module.scss";
import FormStyles from "../components/signup/form.module.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Signup form | May 2 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-2.png" url="https://cssgrid31.brett.cool" pathname="/may-2" mdxType="Metatags" />
    <div className={LayoutStyles.canvas}>
  <div className={LayoutStyles.page}>
    <div className={LayoutStyles.sidebar} />
    <div className={LayoutStyles.main}>
      <div className={LayoutStyles.intro}>
        <h1>Get started</h1>
        <h2>
          Join Milligram to share your coffee brewing prints, and recipes with
          our community!
        </h2>
      </div>
      <form className={FormStyles.form}>
        <div className={LayoutStyles.hint}>
          <p>
            Already have an account? <a href="#">Sign in →</a>
          </p>
        </div>
        <label htmlFor="email">Email</label>
        <input id="email" type="email" placeholder="hello@example.com" />
        <label htmlFor="password">Password</label>
        <input id="password" type="password" placeholder="••••••••" />
        <button>Sign up</button>
      </form>
    </div>
  </div>
    </div>
    <Footer date={2} prev={true} next={true} mdxType="Footer">
      <p>{`Ahh, signup forms. A common UI playground (along with Weather apps, and Twitter clients). Here’s a signup screen for Milligram, a fictional coffee social network, which I have now half the mind to build.`}</p>
      <p>{`CSS Grid areas provide an interesting option for responsive design. Being able to specify layouts visually with an ascii representation.`}</p>
      <p>{`Granted, this is a simple responsive implementation, but you could see this coming in handy with more complex layouts (more on that later).`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      